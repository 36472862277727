import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EmailModel } from '../../models/sendEmail.model';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {
  
  send: EmailModel;

  endpoint:string;

  email:string;
  name:string;
  phone:string;
  type:string;
  invitados:string;
  fecha:Date;
  message:string;
  


  constructor(private http: HttpClient) {
    this.send = new EmailModel();
    this.http = http;
   }

  ngOnInit() {
    this.send = new EmailModel();
   this.endpoint = "http://www.bellarosa.com.ar/sendEmail.php"

  }

  

  sendEmail(log: NgForm){

    if(this.send.email === "") {
      this.send.email = undefined;
    }else if (this.send.telefono === "") {
      this.send.telefono = undefined;
    }
    if(this.send.fecha) {
      let fecha = this.send.fecha;
      debugger;
      var diaFecha = fecha.getDate();
      var mesFecha = fecha.getMonth() + 1;
      var anoFecha = fecha.getFullYear();
    }
      let postVars = {
      email : this.send.email,
      name : this.send.nombre,
      message: "Fecha: " + diaFecha + " de " + mesFecha + " de " + anoFecha + "\n" +"Telefono: " + this.send.telefono + "\n" + "Tipo de evento: " + this.send.tipoEvento + "\n" + "Cantidad de invitados: " + this.send.cantInvitados + "\n" + "Mensaje: \n" + this.send.mensaje
    }
    if(this.send.email === undefined || this.send.telefono === undefined){
      Swal.fire({icon: 'error', title: 'Debe completar todos los campos!', text:'Principalmente email y telefono'});
    }else { 
    this.http.post(this.endpoint, postVars)
        .subscribe(
            response => Swal.fire({icon: 'success', text: 'Su mensaje se envio correctamente!'}),
            response => console.log(response)
        )
      }
  }


}
